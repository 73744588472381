import {
  FETCH_OPTIONS_REQUEST,
  FETCH_OPTIONS_SUCCESS,
  FETCH_OPTIONS_ERROR,
} from './actions';
import { REDUCERS } from '../../common/constants';
import { createReducer } from '../../common/helpers';

const schema = {
  loading: false,
  error: false,
  options: {
    loading: false,
    error: false,
    partners: [],
    businessUnits: [],
    plants: [],
    quoteTypes: [],
    costVarianceTypes: [],
    quantityVarianceTypes: [],
    programTypes: [],
    users: [],
    regions: [],
    currencies: [],
    commodities: [],
    costGroups: [],
    costFields: [],
    programs: [],
    operationTypes: [],
    materialTypes: [],
    pmMaterialTypes: [],
    costSourceTypes: [],
    plantPrefMaterialTypes: [],
    costCategory: [],
    constConditions: [],
    partnerReports: [],
    updatedCostFields: [],
    bomOwnerRoleTypes: [],
    negotiatedCostSource: [],
    fileUploadRestrictionsData: {
      restrictLabelText: '',
      restrictUpload: false,
    },
  },
};

const reducer = {
  // Program Options
  [FETCH_OPTIONS_REQUEST]: state => {
    return {
      ...state,
      options: {
        ...state.options,
        ...REDUCERS.LOADING,
      },
    };
  },
  [FETCH_OPTIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      options: {
        ...REDUCERS.SUCCESS,
        ...payload,
      },
    };
  },
  [FETCH_OPTIONS_ERROR]: (state, { payload: error }) => ({
    ...state,
    options: {
      ...state.options,
      ...REDUCERS.ERROR,
      error,
    },
  }),
};

export default createReducer(schema, reducer);
