import * as service from '../common/service';
import { QNP } from '../common/constants';
import { check } from '../common/session';

const api = `${QNP}/file-upload/file-upload-restrictions`;

const dataUploadRestrictionService = {
  getFileUploadRestrictions: () => {
    const { roles } = check();
    return service.get(`${api}`, roles);
  },
};

export default dataUploadRestrictionService;
