import React, { useCallback } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormClose } from 'grommet-icons';
import { func, object, arrayOf, string, bool, oneOfType } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Button';
import DataTable from '../DataTable';
import DisplayLabel from './Components/DisplayLabel';
import { useCallbackDispatch } from '../../common/hooks';
import { showMaterialVarianceType } from '../../common/helpers';
import {
  VarianceHistory,
  Pagination,
  Payload,
  User,
  SharedMaterials,
} from '../../common/prop-types';
import Comments from '../Comments';

import {
  ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY,
  UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY,
  REJECT_REQUEST_ACTION_VARIANCE_QUANTITY,
  CREATE_QUANTITY_VARIANCE_REQUEST,
} from '../../redux/variances/actions';
import { USER_ROLES, REDUCER_VARIANCES_TYPES } from '../../common/constants';
import Loader from '../Loader';
import {
  UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM,
  CREATE_QUANTITY_VARIANCE_REQUEST_BOM,
} from '../../redux/bom/actions';

const entityType = 'MaterialVariance';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(26),
      height: theme.spacing(12),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  },
}));

const modelAdapter = (isBom, selectedVariance, plants) => {
  const materialIdentifiers = !isBom
    ? selectedVariance?.newBom?.component?.materialIdentifiers
    : selectedVariance?.materialIdentifiers;

  return {
    id: !isBom ? selectedVariance?.id : selectedVariance?.materialVariance?.id,
    approved: !isBom
      ? selectedVariance?.approved
      : selectedVariance?.materialVariance?.approved,
    componentId: !isBom ? selectedVariance?.component?.id : selectedVariance.id,
    component:
      materialIdentifiers?.find(item => {
        return item.ownedBy === 'hpe';
      }) || {},
    supplier:
      materialIdentifiers?.find(item => {
        return item.ownedBy === 'supplier';
      }) || {},
    newBomId:
      selectedVariance?.newBom?.id ||
      selectedVariance?.materialVariance?.newBomId ||
      selectedVariance?.newBomId,
    bomMasterId: selectedVariance?.bomMasterId,
    program: !isBom
      ? selectedVariance?.programMaterial?.program
      : selectedVariance?.program,
    owner: !isBom
      ? selectedVariance?.programMaterial?.bomOwner
      : selectedVariance?.bomOwner,
    plant:
      plants.find(
        x =>
          x.id ===
          (!isBom
            ? selectedVariance?.newBom?.plantId
            : selectedVariance?.plant?.id)
      ) || {},
    materialCategory: !isBom
      ? selectedVariance?.newBom?.component?.type
      : selectedVariance?.materialType,
    currentQuantity:
      selectedVariance?.currentQuantity ||
      selectedVariance?.materialVariance?.currentQuantity ||
      selectedVariance?.unitQty,
    previousQuantity:
      selectedVariance?.previousQuantity ||
      selectedVariance?.materialVariance?.previousQuantity ||
      '-',
    deltaQuantity:
      selectedVariance?.deltaQuantity ||
      selectedVariance?.materialVariance?.deltaQuantity ||
      '-',
    deltaPercent:
      selectedVariance?.deltaPercent ||
      selectedVariance?.materialVariance?.deltaPercent ||
      '-',
    targetRole:
      selectedVariance?.targetRole ||
      selectedVariance?.materialVariance?.targetRole,
    varianceType: showMaterialVarianceType(
      selectedVariance?.varianceType ||
        selectedVariance?.materialVariance?.varianceType
    ),
    description: selectedVariance?.parentMaterial?.description,
    programQuoteId: selectedVariance?.programQuoteId,
  };
};

const QuantityVarianceModal = ({
  closeDrawer,
  selectedVariance = {},
  plants = [],
  historyProps: { historyData, fetchHistory, historyLoading },
  sharedMaterialsProps: {
    sharedMaterialsData,
    fetchSharedProcuredMaterials,
    sharedMaterialsLoading,
  },
  userData,
  payload,
  isBom = false,
  programQuoteId,
  plantId,
  materialId,
  loading,
  type,
  fileUploadRestrictionsData,
}) => {
  const { id, roles } = userData;
  const { restrictUpload, restrictLabelText } = fileUploadRestrictionsData;
  const { pagination, filter, orderBy } = payload;
  const [newQuantity, setNewQuantity] = React.useState('');
  pagination.pageNumber = 1;
  pagination.infinite = false;
  const [justification, updateJustification] = React.useState('');
  const updateVarianceQuantity = useCallbackDispatch(
    UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY
  );
  const acceptVarianceQuantity = useCallbackDispatch(
    ACCEPT_REQUEST_ACTION_VARIANCE_QUANTITY
  );
  const rejectVarianceQuantity = useCallbackDispatch(
    REJECT_REQUEST_ACTION_VARIANCE_QUANTITY
  );
  const createQuantityVariance = useCallbackDispatch(
    CREATE_QUANTITY_VARIANCE_REQUEST
  );

  const updateBomVarianceQuantity = useCallbackDispatch(
    UPDATE_REQUEST_ACTION_VARIANCE_QUANTITY_BOM
  );

  const createBomQuantityVariance = useCallbackDispatch(
    CREATE_QUANTITY_VARIANCE_REQUEST_BOM
  );

  const updateBySupplier = requestObject => {
    if (requestObject.type === REDUCER_VARIANCES_TYPES.BOM) {
      updateBomVarianceQuantity(requestObject);
    } else {
      updateVarianceQuantity(requestObject);
    }
  };
  const createBySupplier = requestObject => {
    if (requestObject.type === REDUCER_VARIANCES_TYPES.BOM) {
      createBomQuantityVariance(requestObject);
    } else {
      createQuantityVariance({ ...requestObject });
    }
  };
  const classes = useStyles();
  const model = modelAdapter(isBom, selectedVariance, plants);
  const onUpdateHistory = useCallback(() => {
    return fetchHistory({
      params: {
        pagination: { pageNumber: 1, pageSize: 6, allItems: false },
      },
      id: model.id,
    });
  }, [model.id, fetchHistory]);

  const onUpdateSharedMaterials = useCallback(() => {
    return fetchSharedProcuredMaterials({
      materialId: model?.componentId,
      plantId: model?.plant.id,
    });
  }, [fetchSharedProcuredMaterials, model]);
  return (
    <Box width={800} role="presentation">
      {loading && (
        <Loader
          hideIcon
          style={{
            zIndex: '1000',
            position: 'fixed',
            right: '350px',
            top: '30%',
          }}
        />
      )}
      <Box display="flex" justifyContent="flex-end" m={2}>
        <IconButton onClick={closeDrawer}>
          <FormClose />
        </IconButton>
      </Box>
      <Box ml={2} mr={2} mb={1} display="flex" flexDirection="row">
        <Box width="50%">
          <DisplayLabel
            label="Status:"
            first
            value={model.approved ? 'Approved' : 'Pending Approval'}
            color={model.approved ? 'primary.main' : 'error.main'}
          />
          <DisplayLabel
            label="Component P/N:"
            value={model.component?.identifier || '--'}
          />
          <DisplayLabel
            label="Supplier P/N:"
            value={model.supplier?.identifier || '--'}
          />
          <DisplayLabel label="Program Name:" value={model?.program?.name} />
          <DisplayLabel label="Owner Name" value={model.owner} />
        </Box>
        <Box width="50%" mr={2}>
          <DisplayLabel
            label="Material Category:"
            first
            value={model.materialCategory || '--'}
          />
          <DisplayLabel label="Plant:" value={model.plant?.code} />
          <DisplayLabel label="Variance Type:" value={model.varianceType} />
          <DisplayLabel label="Description:" value={model.description} />
        </Box>
      </Box>
      <Box ml={2} mr={2} mb={1} display="flex" flexDirection="row">
        <Box ml={2} mr={2} width="50%">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="historyPanelbh-content"
              id="historyPanelbh-header"
            >
              Quantity History
            </AccordionSummary>
            <AccordionDetails>
              <DataTable
                style={{ maxHeight: '240px' }}
                onUpdate={onUpdateHistory}
                columns={[
                  { key: 'effectiveFrom', type: 'date', size: 155 },
                  {
                    key: 'quantity',
                    size: 155,
                  },
                ]}
                data={historyData?.slice(0, 6)}
                loading={historyLoading}
                base
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        {(userData.roles.includes(USER_ROLES.SPM) ||
          userData.roles.includes(USER_ROLES.SUPPLIER)) && (
          <Box mr={2} width="50%">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sharedMaterialsbh-content"
                id="sharedMaterialsbh-header"
              >
                Shared Materials
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <DataTable
                    style={{ maxHeight: '240px' }}
                    onUpdate={onUpdateSharedMaterials}
                    columns={[
                      { key: 'name', label: 'Program Name', size: 145 },
                      {
                        label: 'SPM',
                        key: 'owner.name',
                        size: 145,
                      },
                    ]}
                    data={sharedMaterialsData}
                    loading={sharedMaterialsLoading}
                    base
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
      <Box m={2} heigth={50} display="block" flexDirection="row">
        <Box m={2} className={classes.root}>
          <Paper variant="outlined" square>
            <Box textAlign="right" fontSize={16}>
              Current qty
            </Box>
            <Box
              textAlign="right"
              fontSize={48}
              fontWeight="bold"
              color="accent.main"
              mt={-1}
            >
              {model.currentQuantity || 0}
            </Box>
          </Paper>
          <Paper variant="outlined" square>
            <Box textAlign="right" fontSize={16}>
              Last Qty
            </Box>
            <Box textAlign="right" fontSize={48} mt={-1}>
              {model.previousQuantity || 0}
            </Box>
          </Paper>
          <Paper variant="outlined" square>
            <Box textAlign="right" fontSize={16}>
              Delta Qty
            </Box>
            <Box textAlign="right" fontSize={24}>
              <ArrowDropUpIcon fontSize="small" color="secondary" />
              {model.deltaQuantity || 0}
            </Box>
          </Paper>
          <Paper variant="outlined" square>
            <Box textAlign="right" fontSize={16}>
              DELTA %
            </Box>
            <Box textAlign="right" fontSize={36} whiteSpace="nowrap">
              <ArrowDropUpIcon fontSize="small" color="secondary" />
              {`${model.deltaPercent || 0}%`}
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box
        mr={4}
        ml={4}
        mb={1}
        hidden={!roles.includes(USER_ROLES.SUPPLIER) || !restrictUpload}
        fontStyle="italic"
        color={restrictUpload ? '#f44336' : '#0091ea'}
        fontSize="large"
        display="flex"
        flexDirection="row"
      >
        {restrictLabelText}
      </Box>
      <div
        style={{ width: '100%' }}
        hidden={!roles.includes(USER_ROLES.SUPPLIER) || restrictUpload}
        id="scrollable-force-tabpanel-0"
      >
        <Box mr={4} ml={4} mb={1} display="flex" flexDirection="row">
          <Box width="40%">
            <Box display="flex">
              <Box component="span" color="text.secondary" pr={1}>
                <Box pt={2} fontSize={16}>
                  New Quantity :
                </Box>
              </Box>
              <Box
                component="span"
                fontWeight="bold"
                display="flex"
                color="accent.main"
                width="50%"
              >
                <OutlinedInput
                  inputProps={{ min: 0 }}
                  id="outlined-adornment-amount"
                  value={newQuantity}
                  onChange={val => setNewQuantity(val.target.value)}
                  type="number"
                  aria-describedby="outlined-amount-helper-text"
                />
              </Box>
            </Box>
          </Box>
          {newQuantity && (
            <Box width="60%" className="pl-4">
              <Box display="flex">
                <Box component="span" color="text.secondary" width="30%" pr={1}>
                  <Box pt={2} fontSize={16}>
                    Justification :
                  </Box>
                </Box>
                <Box
                  component="span"
                  fontWeight="bold"
                  display="flex"
                  color="accent.main"
                  width="70%"
                >
                  <Select
                    style={{ width: '100%' }}
                    variant="outlined"
                    value={justification}
                    onChange={val => updateJustification(val.target.value)}
                  >
                    <MenuItem value="eco">ECO</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box mr={4} ml={4} mb={1} display="flex" justifyContent="flex-end">
          <Box mx={1}>
            <Button
              onClick={() => {
                setNewQuantity('');
                updateJustification('');
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box mx={1}>
            <Button
              type="submit"
              btnType="primary"
              disabled={loading || !newQuantity || !justification}
              onClick={() => {
                if (model.id) {
                  updateBySupplier({
                    materialVarianceId: model.id,
                    materialId,
                    justification,
                    newQuantity,
                    userId: id,
                    newBomId: model.newBomId,
                    actionType: 'justify-variance',
                    action: {
                      pagination,
                      filter,
                      orderBy,
                      programQuoteId,
                      plantId,
                    },
                    type,
                    returnObj: true,
                    eturnObjType: type,
                  });
                } else {
                  createBySupplier({
                    materialVarianceId: model.id,
                    bomLineId: selectedVariance.id,
                    values: {
                      materialVarianceId: model.id,
                      bomLineId: selectedVariance.id,
                      materialId,
                      newQuantity,
                      newBomId: model.newBomId,
                      bomMasterId: model.bomMasterId,
                      programQuoteId,
                      plantId,
                      justification,
                      UserId: userData.id,
                    },
                    payload: {
                      pagination,
                      filter,
                      orderBy,
                      programQuoteId,
                      plantId,
                    },
                    type,
                    returnObj: true,
                    eturnObjType: type,
                  });
                }
                closeDrawer();
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </div>
      <div
        role="tabpanel"
        hidden={!roles.includes(USER_ROLES.SPM)}
        id="scrollable-force-tabpanel-1"
        aria-labelledby="scrollable-force-tab-1"
      >
        <Box mr={4} ml={4} mb={1} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button
              // IF APPROVED OR NOT SPM/ADMIN
              disabled={
                loading ||
                ((model.approved || USER_ROLES.SUPPLIER !== model.targetRole) &&
                  USER_ROLES.SPM !== model.targetRole)
              }
              btnType="primary"
              type="submit"
              onClick={() => {
                acceptVarianceQuantity({
                  materialVarianceId: model.id,
                  userId: id,
                  action: {
                    pagination,
                    filter,
                    orderBy,
                    programQuoteId,
                    plantId,
                  },
                  type,
                });
                closeDrawer();
              }}
            >
              Approve
            </Button>
          </Box>
          <Box>
            <Button
              // IF APPROVED OR NOT SPM/ADMIN
              disabled={
                loading ||
                ((!model.approved ||
                  USER_ROLES.SUPPLIER !== model.targetRole) &&
                  USER_ROLES.SPM !== model.targetRole)
              }
              btnType="primary"
              type="submit"
              onClick={() => {
                rejectVarianceQuantity({
                  materialVarianceId: model.id,
                  userId: id,
                  action: {
                    pagination,
                    filter,
                    orderBy,
                    programQuoteId,
                    plantId,
                  },
                  type,
                });
                closeDrawer();
              }}
            >
              Reject
            </Button>
          </Box>
        </Box>
      </div>
      <Comments
        entityId={model.id}
        disabled={!model.id}
        entityType={entityType}
        userId={id}
        userRoles={roles}
      />
    </Box>
  );
};
QuantityVarianceModal.propTypes = {
  closeDrawer: func,
  historyProps: VarianceHistory,
  sharedMaterialsProps: SharedMaterials,
  plants: arrayOf(object),
  // eslint-disable-next-line react/forbid-prop-types
  selectedVariance: object,
  pagination: Pagination,
  userData: User,
  payload: Payload,
  isBom: bool,
  programQuoteId: string,
  plantId: string,
  materialId: string,
  loading: bool,
  type: string,
  fileUploadRestrictionsData: oneOfType(object),
};

export default QuantityVarianceModal;
